export default () => ({
  fromType: "static_number",
  from: null,
  name: "",
  description: "",
  segment: "",
  opens: "",
  clicks: [],
  templateMessage: "",
  // scheduleDateTime: "",
  chunkSize: 100,
  chunkPages: 1,
  chunls: [],
});
